import { useEffect, useState } from "react";
import assets from "../../assets/assets";
import useLang, { useLangString } from "../../utils/languageHandler";

import './joinPage.scss';
import { useNavigate, useParams } from "react-router-dom";
import LanguageSelect from "../../modules/languageSelect/languageSelect";

export default function JoinPage() {
    const params = useParams();

    const [ gamecode, setGamecode ] = useState<string>('');
    const [ joining, setJoining ] = useState<boolean>(false);
    const [ joiningError, setJoiningError ] = useState<string>('');
    const navigate = useNavigate();

    const gamecode_regex = /^[a-z0-9]{8}$/i;

    useEffect(() => {}, [ joining ]);

    return (
        <div className="joinpage">
            <header>
                <div></div>
                <LanguageSelect />
            </header>
            <div className="container">
                <img src={assets.images.logo} alt="Logo" className="logo" />
                <h1 id="title">{useLang('loginpage.headline')}</h1>
                <p id="description">{useLang('loginpage.teaser')}</p>
                <form className="code_input" onSubmit={e => {
                    e.preventDefault();
                    setJoiningError('');

                    if (joining) return;
                    if (!gamecode_regex.test(gamecode)) return;

                    setJoining(true);
                    fetch(`${process.env.REACT_APP_API_URI}/api/games/${params.lang}/${gamecode}`).then(async res => {
                        const json = await res.json();
                        if (json.error) {
                            setJoiningError(`errors.${json.error_code}`);
                            setJoining(false);
                            return;
                        }
                        if (json.data.finished) return navigate(`./${gamecode}/teamname`);
                        navigate(`./${gamecode}`);
                    });
                }}>
                    <input type="text" className="input" id="code" name="code" maxLength={8} placeholder={useLangString('loginpage.inputbox')} onChange={e => {
                        setGamecode(e.currentTarget.value);
                    }} disabled={joining} />
                    <button type="submit" className="btn btn-blue" disabled={joining || !gamecode_regex.test(gamecode)}>{useLang('loginpage.submit')}</button>
                </form>
                <p id="err">{useLang(joiningError)}</p>
            </div>

            <footer>
                <img src={assets.images.background} alt="background" className="background_image" />
                <p>{useLang('loginpage.support')}</p>
            </footer>
        </div>
    );
}