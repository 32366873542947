import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LanguageSelect from '../../modules/languageSelect/languageSelect';

import './scoreboardPage.scss';

export default function ScoreboardPage() {
    const params = useParams();

    const [title, setTitle] = useState<string>();
    const [scores, setScores] = useState<{[key: string]: any}[]>();

    useEffect(() => {
        if (!scores) {
            fetch(`/api/scoreboard/${params.lang}/${params.game_identifier}`, { method: 'GET' }).then(async res => {
                const json = await res.json();
        
                if (json.error) return alert(json.reason);
                setScores(json.data.scoreboard);
                setTitle(json.data.title);

                setTimeout(() => {
                    const el = document.getElementById(window.location.hash.substring(1));
                    if (el) window.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
                }, 500);
            });
        }
    });

    return (
        <div className="scoreboard-page">
            <header>
                <div></div>
                <LanguageSelect />
            </header>

            <div className="content">
                <div className="head-title">
                    <h1>SCOREBOARD</h1>
                    <h3>{title}</h3>
                </div>
                <div className="scores">{(scores ?? []).length <= 0
                    ? <p id="empty">No scores yet</p>
                    : scores!.map(score =>
                        <div className={["score", window.location.hash.substring(1) === score.game_code ? 'searched' : null].join(' ')} id={score.game_code} key={score.game_code}>
                            <div className="team">
                                <p className="name">{score.name}</p>
                                <p className="time">{Math.floor(score.time / 60 / 24).toString().padStart(2, '0')}:{Math.floor((score.time / 60) % 60).toString().padStart(2, '0')}:{Math.floor(score.time % 60).toString().padStart(2, '0')}</p>
                            </div>
                            <p className='place'>#{score.place}</p>
                        </div>
                    )
                }</div>
            </div>
        </div>
    );
}