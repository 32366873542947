import useLang from "../../utils/languageHandler";

import './websocketStatus.scss';

export default function WebsocketStatus({ status }: { status: string }) {
    return (
        <div className="websocket-status" {...{connected: (status === 'connected' ? 'true' : 'false')}}>
            <div className="container">
                <p id="title">{useLang(`socket.${status}.title`)}</p>
                <p id="status">{useLang(`socket.${status}.description`)}</p>
            </div>
        </div>
    );
}