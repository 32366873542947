import { useEffect, useState } from "react";
import { HiChevronRight, HiChevronLeft, HiInformationCircle, HiMiniXCircle } from "react-icons/hi2";

import './imageContainer.scss';
import useLang from "../../utils/languageHandler";
import React from "react";

export default function ImageContainer({
    gameIdentifier,
    images,
    history,
}: {
    gameIdentifier: string
    images: string[]
    history?: string
}) {
    const [ showHistory, setShowHistory ] = useState<boolean>(false);
    const [ selectedImage, setSelectedImage ] = useState<number>(0);
    const [ slideStartPos, setSlideStartPos ] = useState<{x: number, y: number} | undefined>();
    const [ slideEndPos, setSlideEndPos ] = useState<{x: number, y: number} | undefined>();

    const [ preloadedImages, setPreloadedImages ] = useState<React.ReactElement[]>([]);

    useEffect(() => {
        if (images.length !== preloadedImages.length) setSelectedImage(0);
        setPreloadedImages(images.map(img => React.createElement(
            "img",
            {
              src: `${process.env.REACT_APP_API_URI}/api/games/assets/${gameIdentifier}/${img}`,
            }
        )));
    }, [images, gameIdentifier]);

    return (
        <div className="image-container">
            <div
                className="images"
                onTouchStart={e => {
                    if (preloadedImages.length <= 1) return;
                    setSlideStartPos({ x: e.touches[0].clientX, y: e.touches[0].clientY });

                    document.querySelector('body')!.style.overflow = 'hidden';
                }}
                onTouchMove={e => {
                    if (!slideStartPos) return;
                    const offset = (e.touches[0].clientX - slideStartPos.x) / (document.querySelector('.image-container .images') as HTMLImageElement).offsetWidth * 100;
                    
                    (document.querySelector('.image-container .images') as HTMLImageElement).style.transform = `translateX(${offset}%)`;
                    setSlideEndPos({ x: e.touches[0].clientX, y: e.touches[0].clientY });
                }}
                onTouchEnd={e => {
                    if (!slideStartPos || !slideEndPos) return;
                    const offset = (slideEndPos.x - slideStartPos.x)  / (document.querySelector('.image-container .images') as HTMLImageElement).offsetWidth * 100;
                    (document.querySelector('.image-container .images') as HTMLImageElement).style.transform = `translateX(0%)`;

                    if (offset > 50) setSelectedImage(prev => (prev <= 0 ? preloadedImages.length : prev) - 1);
                    else if (offset < -50) setSelectedImage(prev => (Math.abs((prev + 1) % preloadedImages.length)));
                
                    document.querySelector('body')!.style.overflow = '';
                }}
            >{(() => {
                let displayImages: JSX.Element[] = [];
                if (preloadedImages.length <= 1)
                    return preloadedImages[selectedImage]; // <img src={preloadedImages[selectedImage]} alt="" />

                for (let i = -1; i <= 1; i++) {
                    displayImages.push(
                        <div
                            key={i}
                            className="image"
                            style={{ transform: `translate(${100 * i}%, 0)` }}
                        >
                            {preloadedImages[((selectedImage + i < 0 ? preloadedImages.length : selectedImage) + i) % preloadedImages.length]}
                            {/* <img src={`${process.env.REACT_APP_API_URI}/api/games/assets/${gameIdentifier}/${preloadedImages[((selectedImage + i < 0 ? preloadedImages.length : selectedImage) + i) % preloadedImages.length]}`} alt=""/> */}
                        </div>
                    );
                }

                return displayImages;
            })()}</div>
            <div className="controls">
                {!history ? null : <HiInformationCircle id="info" onClick={() => setShowHistory(true)} />}
                {images.length <= 1 ? null : <>
                    <div className="arrows">
                        <HiChevronLeft id="left" onClick={async () => {
                            // for (let i = 1; i <= 50; i++) {
                            //     (document.querySelector('.image-container .images') as HTMLImageElement).style.transform = `translateX(${i*2}%)`;
                            //     await new Promise<void>((res, rej) => setTimeout(res, 1));
                            // }
                            setSelectedImage(prev => (prev <= 0 ? preloadedImages.length : prev) - 1);
                            // (document.querySelector('.image-container .images') as HTMLImageElement).style.transform = `translateX(0%)`;
                        }} />
                        <HiChevronRight id="right" onClick={async () => {
                            // for (let i = 1; i <= 50; i++) {
                            //     (document.querySelector('.image-container .images') as HTMLImageElement).style.transform = `translateX(-${i*2}%)`;
                            //     await new Promise<void>((res, rej) => setTimeout(res, 1));
                            // }
                            setSelectedImage(prev => (Math.abs((prev + 1) % preloadedImages.length)));
                            // (document.querySelector('.image-container .images') as HTMLImageElement).style.transform = `translateX(0%)`;
                        }} />
                    </div>
                    <div className="dots">{
                        preloadedImages.map((img: React.ReactElement, i: number) => <div className={['dot', (selectedImage === i ? 'selected' : '')].join(' ')} key={i}></div>)
                    }</div>
                </>}
            </div>
            <div className="history_overlay" hidden={!showHistory}>
                <HiMiniXCircle id="close" onClick={() => setShowHistory(false)} />
                <h1>{useLang('puzzlepage.history')}</h1>
                <p>{history ?? ''}</p>
            </div>
        </div>
    );
}