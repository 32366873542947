import useLang from "../../utils/languageHandler";

import './infoGame.scss'

export function GameInfo({onSubmit}: {onSubmit?: () => void}) {
    return (
        <div className="info-page">
            <button className="btn btn-green" onClick={() => onSubmit ? onSubmit() : null}>{useLang('puzzlepage.continue')}</button>
        </div>
    );
}