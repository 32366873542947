import './App.scss';
import { Route, Routes } from 'react-router-dom';
import JoinPage from './pages/join/joinPage';
import GamePage from './pages/game/gamePage';
import ScoreboardPage from './pages/scoreboard/scoreboardPage';
import TeamNamePage from './pages/teamName/teamNamePage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<JoinPage />} />
        <Route path="/scoreboard/:game_identifier" element={<ScoreboardPage />} />
        <Route path="/:gamecode/teamname" element={<TeamNamePage />} />
        <Route path="/:gamecode" element={<GamePage />} />
      </Routes>
    </div>
  );
}

export default App;
