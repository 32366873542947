import { useEffect, useRef, useState } from "react";
import useLang, { useLangString } from "../../utils/languageHandler";

import './puzzleGame.scss';

export default function GamePuzzle({
    onSubmit,
    onHint,
    game_identifier,
    map,
    hints,
    answer,
    onAnswerChange
}: {
    onSubmit?: () => void,
    onHint?: () => void,
    game_identifier: string,
    map?: string,
    hints?: string[],
    answer: string,
    onAnswerChange: (answer: string) => void 
}) {
    const [ selectedHint, setSelectedHint ] = useState<string | undefined>();

    function selectHint(hint: string) {
        setSelectedHint(prev => prev === hint ? '' : hint);
    }

    return (
        <div className="puzzle-page">
            <div className="hints">
                <button className="btn btn-green" onClick={() => selectHint('map')}>{useLang('puzzlepage.map')}</button>
                {
                    (() => {
                        let hint_elements: JSX.Element[] = [];
                        for (let i = 1; i <= 3; i++) {
                            hint_elements.push(<HintButton key={i}
                                lang_code={`puzzlepage.hint${i}`}
                                hint={(hints ?? []).length >= i ? hints![i-1] : undefined}
                                onHint={(hints ?? []).length >= (i-1) ? () => {if (onHint) onHint(); selectHint(`hint${i}`); } : undefined}
                                onSelect={() => selectHint(`hint${i}`)}
                            />);
                        }
                        return hint_elements;
                    })()
                }
            </div>
            <div className={['hint-container', ((selectedHint ?? '').length <= 0 ? 'hidden' : '')].join(' ')}>
                <HintContent key={-1}
                    hidden={(selectedHint ?? '').length > 0}
                    selected={(selectedHint ?? '') === `map`}
                    lang_code={`puzzlepage.map`}
                    hint={<img src={`${process.env.REACT_APP_API_URI}/api/games/assets/${game_identifier}/${map}`} alt={useLangString('puzzlepage.map')} />}
                />
                {(() => {
                    let hint_elements: JSX.Element[] = [];
                    for (let i = 0; i < (hints ?? []).length; i++) {
                        hint_elements.push(<HintContent key={i}
                            hidden={(selectedHint ?? '').length > 0}
                            selected={(selectedHint ?? '') === `hint${i+1}`}
                            lang_code={`puzzlepage.hint${i+1}`}
                            hint={hints![i]}
                        />);
                    }
                    return hint_elements;
                })()}
            </div>
            <div className="submit">
                <input type="text" autoComplete="off" autoCorrect="off" className="input" name="answer" id="answer" onChange={e => onAnswerChange(e.target.value)} value={answer} placeholder={useLangString('puzzlepage.answer')} />
                <button className="btn btn-green" onClick={() => {
                    if (answer.length > 0 && onSubmit) onSubmit();
                }}>{useLang('puzzlepage.submit')}</button>
            </div>
        </div>
    );
}

function HintButton({
    lang_code,
    hint,
    onSelect,
    onHint,
}: {
    lang_code: string,
    hint?: string
    onSelect?: () => void
    onHint?: () => void
}) {
    return (
        <button className={['btn', hint ? 'btn-green' : 'btn-blue'].join(' ')} disabled={!onHint} onClick={() => {
            if (hint) return onSelect ? onSelect() : null;
            if (onHint) onHint();
        }}>{useLang(lang_code)}</button>
    );
}

function HintContent({
    hidden,
    selected,
    lang_code,
    hint
}: {
    hidden: boolean,
    selected: boolean,
    lang_code: string,
    hint?: string | JSX.Element
}) {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current)
            ref.current.style.height = `${ref.current.scrollHeight ?? 0}px`;
    });

    return (
        <div ref={ref} className={['hint', (hidden ? 'hidden' : ''), (selected ? 'selected' : '')].join(' ')}>
            <p>{useLang(lang_code)}</p>
            <div className="hint-container">{
                typeof(hint) === 'string'
                    ? <div dangerouslySetInnerHTML={{__html: hint ?? ''}}></div>
                    : hint
            }</div>
        </div>
    );
}