import assets from "../../assets/assets";
import useLang from "../../utils/languageHandler";

import './resultPage.scss';

export default function ResultPage({
    result,
    answer,
    onClick,
}: {
    result?: 'correct' | 'wrong',
    answer?: string,
    onClick?: () => void
}) {
    return (
        <div className={["result", (!result ? 'hidden' : '')].join(' ')} onClick={onClick}>
            <img className="background" src={assets.images.background} alt="background" />
            <div className="container">
                <img src={result === 'correct' ? assets.images.checksign : assets.images.wrongsign} alt={result} />
                {result === 'correct' ? <ResultPageCorrect /> : <ResultPageWrong answer={answer} />}
            </div>
        </div>
    );
}

function ResultPageCorrect() {
    return (
        <div className="result-content">
            <p id="title">{useLang('puzzlepage.correctanswer.title')}</p>
            <p id="description">{useLang('puzzlepage.correctanswer.description')}</p>
        </div>
    );
}

function ResultPageWrong({ answer }: { answer?: string }) {
    return (
        <div className="result-content">
            <p id="answer">{`"${answer}"`}</p>
            <p id="title">{useLang('puzzlepage.wronganswer.title')}</p>
            <p id="description">{useLang('puzzlepage.wronganswer.description')}</p>
        </div>
    );
}