import { useEffect, useRef, useState } from "react";
import WebsocketStatus from "../../modules/websocketStatus/websocketStatus";
import { socket } from "../../socket";
import { useBeforeUnload, useNavigate, useParams } from "react-router-dom";
import LanguageSelect from "../../modules/languageSelect/languageSelect";

import './teamNamePage.scss';
import assets from "../../assets/assets";
import useLang, { useLangString } from "../../utils/languageHandler";

export default function TeamNamePage() {
    const params = useParams();
    const navigate = useNavigate();
    
    const loaded = useRef<boolean>(false);
    const [ socketStatus, setSocketStatus ] = useState<string>('connected');
    const [ isLeader, setIsLeader ] = useState<boolean>(false);
    const [ teamname, setTeamname ] = useState<string>('');
    const [ data, setData ] = useState<{[key: string]: any}>();

    useBeforeUnload(() => {
        socket.disconnect();
    });

    useEffect(() => {
        if (!data) {
            fetch(`${process.env.REACT_APP_API_URI}/api/games/${params.lang}/${params.gamecode}`).then(async res => {
                const json = await res.json();
                if (json.error) return navigate(`..`);
                if (!json.data.finished) return navigate(`..`);
                setData(json.data);
            });
        }

        if (!loaded.current) {
            if (!socket.connected) {
                setSocketStatus('connecting');
                socket.connect();
            } else onConnect();
            loaded.current = true;
        }

        function onConnect() {
            setSocketStatus('connected');

            socket.emit('teamname join', params.lang, params.gamecode, (leader: boolean, current_value: string) => {
                setIsLeader(leader);
                setTeamname(current_value);
            });
        }

        function onBrutal() {
            setSocketStatus('disconnected');
            navigate(`/${params.lang}/scoreboard/${data?.game_identifier}#${params.gamecode}`);
        }

        function onDisconnect() {
            setSocketStatus('disconnected');
        }

        function onChange(value: string) {
            setTeamname(value);
        }

        function onUpgrade() {
            socket.emit('teamname upgrade', (leader: boolean) => {
                setIsLeader(leader);
            })
        }

        function onFinish() {
            navigate(`/${params.lang}/scoreboard/${data?.game_identifier}#${params.gamecode}`);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('brutal', onBrutal);
        socket.on('change', onChange);
        socket.on('upgrade', onUpgrade);
        socket.on('finish', onFinish);
        
        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('brutal', onBrutal);
            socket.off('change', onChange);
            socket.off('upgrade', onUpgrade);
            socket.off('finish', onFinish);
        }
    }, [params.lang, params.gamecode, navigate, data]);

    return (
        <div className="teamname-page">
            <header>
                <div></div>
                <LanguageSelect />
            </header>
            <div className="content">
                <h1 className="head-title">{useLang('teamnamepage.title')}</h1>
                <div className="time">
                    <p>{useLang('teamnamepage.time_title')}</p>
                    <h3>{Math.floor((data?.time ?? 0) / 60 / 24).toString().padStart(2, '0')}:{Math.floor(((data?.time ?? 0) / 60) % 60).toString().padStart(2, '0')}:{Math.floor((data?.time ?? 0) % 60).toString().padStart(2, '0')}</h3>
                </div>
                <div className="team-input">
                    <p>{useLang('teamnamepage.label')}</p>
                    {isLeader
                        ? <TeamLeaderPage teamname={teamname} onChange={val => {setTeamname(val); socket.emit('teamname change', val)}} />
                        : <TeamViewerPage teamname={teamname} />
                    }
                </div>
            </div>
            <WebsocketStatus status={socketStatus} />

            <footer>
                <img src={assets.images.background} alt="background" className="background_image" />
                <p>{useLang('loginpage.support')}</p>
            </footer>
        </div>
    );
}

function TeamLeaderPage({
    teamname,
    onChange
}: {
    teamname: string
    onChange: (val: string) => void
}) {
    return (
        <div className="team-leader">
            <form onSubmit={e => {
                e.preventDefault();

                socket.emit('teamname submit', teamname);
            }}>
                <input type="text" className="input" name="teamname" id="teamname" value={teamname} maxLength={16} onChange={e => onChange(e.currentTarget.value)} placeholder={useLangString('teamnamepage.placeholder')} />
                <button className="btn btn-blue" type="submit" disabled={teamname.length < 3}>OK</button>
            </form>
            <p id="muted">{useLang('teamnamepage.muted')}</p>
        </div>
    );
}

function TeamViewerPage({
    teamname
}: {
    teamname: string
}) {
    return (
        <div className="team-view">
            <p>{useLang('teamnamepage.viewer')}</p>
            <p id="teamname">{teamname}</p>
        </div>
    );
}