import logo from './logo.png';
import background from './background.jpg';
import checksign from './checksign.png';
import wrongsign from './wrongsign.png';

const assets = {
    images: {
        logo: logo,
        background: background,
        checksign: checksign,
        wrongsign: wrongsign,
    }
};

export default assets;