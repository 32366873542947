import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useBeforeUnload } from "react-router-dom";
import useLang from "../../utils/languageHandler";
import { socket } from "../../socket";

import './gamePage.scss';
import LanguageSelect from "../../modules/languageSelect/languageSelect";
import ResultPage from "./resultPage";
import WebsocketStatus from "../../modules/websocketStatus/websocketStatus";
import ImageContainer from "./imageContainer";
import GamePuzzle from "./puzzleGame";
import { GameInfo } from "./infoGame";

export default function GamePage() {
    const params = useParams();
    const navigate = useNavigate();

    const loaded = useRef<boolean>(false);
    const loaded_lang = useRef<string>();
    const [ socketStatus, setSocketStatus ] = useState<string>('connected');
    const [ puzzle, setPuzzle ] = useState<Record<string, any> | undefined>();
    
    const [ result, setResult ] = useState<'correct' | 'wrong' | undefined>();
    const [ answer, setAnswer ] = useState<string>('');
    const [ submittedAnswer, setSubmittedAnswer ] = useState<string>('');

    useEffect(() => {
        if (!loaded.current) {
            loaded_lang.current = params.lang;
            fetch(`${process.env.REACT_APP_API_URI}/api/games/${params.lang}/${params.gamecode}`).then(async res => {
                const json = await res.json();
                if (json.error) return navigate('..');
                if (json.data.finished) return navigate('./teamname');
                setPuzzle(json.data);
            });
            if (!socket.connected) {
                setSocketStatus('connecting');
                socket.connect();
            }
            loaded.current = true;
        }

        if (loaded_lang.current !== params.lang) {
            socket.emit('update lang', params.lang, (gamedata: Record<string, any>) => {
                setPuzzle(gamedata);
            });
            loaded_lang.current = params.lang;
        }

        function onConnect() {
            setSocketStatus('connected');

            socket.emit('join', params.lang, params.gamecode, (gamedata: Record<string, any>) => {
                setPuzzle(gamedata);
            });
        }

        function onBrutal() {
            setSocketStatus('disconnected');
            navigate('..');
        }

        function onDisconnect() {
            setSocketStatus('disconnected');
        }

        function onUpdate(gamedata: Record<string, any>) {
            console.log(gamedata);
            setPuzzle(gamedata);
        }

        function onCorrect() {
            setResult('correct');
            setAnswer('');
        }

        function onFinish() {
            navigate(`./teamname`);
            setAnswer('');
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('brutal', onBrutal);
        socket.on('update', onUpdate);
        socket.on('correct', onCorrect);
        socket.on('finish', onFinish);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('brutal', onBrutal);
            socket.off('update', onUpdate);
            socket.off('correct', onCorrect);
            socket.off('finish', onFinish);
        }
    }, [navigate, params.gamecode, params.lang, puzzle]);

    useBeforeUnload(() => {
        socket.disconnect();
    });

    return (
        <div className="gamepage">
            <header>
                <div></div>
                <div className="percentage">{`${puzzle?.percentage ?? 0}%`}</div>
                <LanguageSelect />
            </header>
            <p id="head-title">{(puzzle?.title ?? '...')}</p>
            <ImageContainer images={puzzle?.images ?? []} gameIdentifier={puzzle?.game_identifier ?? ''} history={puzzle?.history} />
            <div className="content">
                <p id="question" dangerouslySetInnerHTML={{__html: puzzle?.question ?? '...'}}></p>
                <div className="container">{
                    puzzle ? (puzzle.puzzle_type === 'info'
                    ?   <GameInfo onSubmit={() => socket.emit('answer')} />
                    :   <GamePuzzle
                            game_identifier={puzzle?.game_identifier}
                            map={puzzle?.map}
                            hints={puzzle?.hints}
                            onHint={() => {socket.emit('hint')}}
                            onSubmit={() => { setSubmittedAnswer(answer); socket.emit('answer', answer, () => { setAnswer(''); setResult('wrong'); }); }}
                            onAnswerChange={answer => setAnswer(answer)}
                            answer={answer}
                        />
                    ): null
                }</div>
            </div>
            <footer>
                <p>{useLang('loginpage.support')}</p>
            </footer>

            <ResultPage answer={submittedAnswer} result={result} onClick={() => setResult(undefined)} />
            <WebsocketStatus status={socketStatus} />
        </div>
    );
}