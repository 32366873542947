import React from "react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

export let lang_file: null | Record<string, any> = null;

export const useLangString = (code: string) => {
    const loaded_lang = useRef<string | undefined>();
    const [text, setText] = useState<string>(code);
    const params = useParams();
    
    useEffect(() => {
        if (loaded_lang.current && loaded_lang.current === `${params.lang}:${code}`) return;

        if (!params.lang) {
            setText("No lang selected");
            return;
        }

        if (!lang_file || (loaded_lang.current && loaded_lang.current.split(':')[0] !== params.lang)) {
            fetch(`${process.env.REACT_APP_API_URI}/api/language/${params.lang}`).then(async response => {
                lang_file = (await response.json()).data;
                processLang(lang_file!);
            });
        } else processLang(lang_file);

        function processLang(lang: Record<string, any>) {
            let obj: Record<string, any> | string = lang;
            const parts = code.split('.');
            for (let i = 0; i < parts.length; i++) {
                if (typeof(obj) === 'string') break;
                if (!(parts[i] in (obj as Record<string, any>))) return setText(code);
                obj = (obj as Record<string, any>)[parts[i]];
            }
            if (typeof(obj) !== 'string') return setText(code);
            setText(obj as string);
        }

        loaded_lang.current = `${params.lang}:${code}`;
    }, [ params, code ]);

    return text;
}

const useLang = (code: string) => {
    // const loaded_lang = useRef<string | undefined>();
    // const [text, setText] = useState<string>(code);
    // const params = useParams();
    
    // useEffect(() => {
    //     if (loaded_lang.current && loaded_lang.current === `${params.lang}:${code}`) return;
    //     loaded_lang.current = `${params.lang}:${code}`;

    //     if (!params.lang) {
    //         setText("No lang selected");
    //         return;
    //     }

    //     fetch(`${process.env.REACT_APP_API_URI}/api/language/${params.lang}`).then(async response => {
    //         const lang = (await response.json()).data;

    //         let obj: Record<string, any> | string = lang;
    //         const parts = code.split('.');
    //         for (let i = 0; i < parts.length; i++) {
    //             if (typeof(obj) === 'string') break;
    //             if (!(parts[i] in (obj as Record<string, any>))) return setText(code);
    //             obj = (obj as Record<string, any>)[parts[i]];
    //         }
    //         if (typeof(obj) !== 'string') return setText(code);
    //         setText(obj as string);
    //     });
    // }, [ params, code ]);

    return <span dangerouslySetInnerHTML={{__html: useLangString(code)}}></span>;
}

// function LanguageElement({text}: {text: string}) {
//     return <span dangerouslySetInnerHTML={{__html: text}}></span>;
// }

export default useLang;

