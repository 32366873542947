import { useEffect, useState } from 'react';

import './languageSelect.scss';
import { LanguageData } from '../../types/languages';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function LanguageSelect() {
    const [toSelect, setToSelect] = useState<boolean>(false);

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="language_select">
            <div className={`current_country ${toSelect ? 'focus' : ''}`} onClick={() => setToSelect(prev => !prev)}>
                <div className="flag">
                    <img src={`${process.env.REACT_APP_API_URI}/api/language/${params.lang}/flag`} alt="" />
                </div>
            </div>
            <div className={`selector ${!toSelect ? 'hidden' : ''}`}>
                <LanguageSelectList onSelect={country => {
                    navigate(`/${country}${location.pathname.split(params.lang!).pop()}${location.hash}`);
                    setToSelect(false);
                }} />
            </div>
        </div>
    );
}

function LanguageSelectList({
    onSelect
} : {
    onSelect?: (country: string) => void
}) {
    const [languages, setLanguages] = useState<LanguageData[]|undefined>();

    useEffect(() => {
        if (!languages) {
            fetch(`${process.env.REACT_APP_API_URI}/api/language`).then(async res => {
                const json = await res.json();
                
                if (!json.success) return console.error(json.error_code);
                setLanguages(json.data);
            });
        }
    });

    return (
        <div className="language_list">
            {(languages ?? []).map((lang, i) => {
                return (
                    <div className="flag" key={i} onClick={() => onSelect ? onSelect(lang.code) : null}>
                        <img src={`${process.env.REACT_APP_API_URI}/api/language/${lang.code}/flag`} alt={lang.country_flag_isoalpha2} />
                    </div>
                );
            })}
        </div>
    );
}