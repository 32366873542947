import { Route, Routes, Navigate } from 'react-router-dom';
import App from './App';

export default function routeBuilder() {
    return (
        <div className="route">
            <Routes>
                <Route path="/" element={<Navigate to='/da' />} />
                <Route path="/:lang/*" element={<App />} />
            </Routes>
        </div>
    );
}